import { terms } from "./Data/terms";

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function arraymove(arr, fromIndex, toIndex) {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
}

export var lumberInterpreter = {
  parse: (text) => {
    //2x6 8/10 10/12 12/14
    //2x8 12/14
    //2x10$ 12/16 2x6 8/10 10/12 12/14 2x8 12/14
    //5/4x6$ 12/16 2x6 8/10 10/12 12/14 2x8 12/14
    //1x6#3 1/4, 5/6 $415/del. 1x8#3 1/6 $440/del.
    //1x8#3 1/6 $440/del.
    //2x8 TL/14 $440/del.
    //TL 2x6 Random                 160
    
    //5/4x6 #3/4
    //12/8’  6/10’  4/12’  13/14’  10/16’

    var str = text.toUpperCase();
    str = str.replaceAll(",", " ");
    str = str.replaceAll(/(?:\r\n|\r|\n)/g, " ");
    str = str.replaceAll("’","");
    //console.log(str);
    str = str.split(" ");
    
    //console.log(str);
    var obj = [];

    str.forEach((element) => {
      obj.push(lumberInterpreter.checkTerms(element.trim()));
    });

    obj = obj.filter(function (item) {
      return item.type !== "undefined";
    });

    //sort
    var priceIndex = [];
    var tempPriceIndex = [];
    var num = 0;

    obj.forEach(function (item) {
      if (item.type == "price") {
        tempPriceIndex.push(num);
      }

      if (tempPriceIndex.length > 1) {
        priceIndex.push(tempPriceIndex);
        tempPriceIndex = [];
      }

      if (item.type == "main") {
        tempPriceIndex = [];
        tempPriceIndex.push(num);
      }

      num++;
    });

    for (var i = 0; i < priceIndex.length; i++) {
      obj = arraymove(obj, priceIndex[i][1], priceIndex[i][0] + 1);
    }
    
    console.log(obj);

    return obj;
  },
  checkTerms: (str) => {
    var val = { text: str, type: "undefined" };
    var arry = str.split("X");

    arry = arry.map((item) => {
      //bit of a hardcore for 5/4...
      if (item == "5/4") {
        return "1.25";
      }

      if (item == "RANDOM" || item == "RL") {
        return "12";
      }

      if (item[0] == "$") {
        item = item.split(/[^\d.]/g);
        return `$${item[1].replaceAll(/[^\d.]/g, "")}`;
      }

      item = item.split(/[^\d.]/g);
      return item[0].replaceAll(/[^\d.]/g, "");
    });

    var txt = `${arry[0]}X${arry[1]}`;

    //Check Terms
    for (var i = 0; i < terms.length; i++) {
      if (txt == terms[i].text) {
        val = { text: txt, type: "main", thickness: arry[0], width: arry[1] };
      }
    }

    if (arry[0][0] == "$") {
      val = {
        text: arry[0],
        type: "price",
        mbft: arry[0].replaceAll(/[^\d.]/g, ""),
      };
    }

    var splitTxt = str.toUpperCase().split("/");

    if (splitTxt.length >= 2 || splitTxt[0] == "TL") {
      if (
        (isNumeric(splitTxt[0]) && isNumeric(splitTxt[1])) ||
        splitTxt[0] == "TL"
      ) {
        val = {
          text: str,
          type: "count",
          bundles: splitTxt[0],
          length: splitTxt[1],
        };
      }
    }

    return val;
  },
};

//easy case
//id bundles and length

//if not, check for combo of main plus length 2 ft - 24 ft in 2 ft increments

//Baxley
//TL 2x6x16
//2x6x4               TL                     150
//Eatonton
//TL 2x6 Random                 160
//Meldrim
//4TL 2x10 Random            175

