export var tableJoin = {
  update: (obj, table) => {
    var list = [];

    var thickness = 0;
    var width = 0;
    var length = 0;
    var bundles = 0;
    var mbft = "";

    obj.forEach((item) => {
      if (item.type == "main") {
        thickness = item.thickness;
        width = item.width;
        length = 0;
        bundles = 0;
      }

      if (item.type == "count") {
        if (item.bundles == "TL") {
          item.bundles = "15";
        }
        if (item.length == undefined || item.length == "RL") {
          item.length = "12";
        }
        length = item.length;
        bundles = item.bundles;
      }

      if (item.type == "price") {
        mbft = item.mbft;
      }

      if (length != 0) {
        list.push({
          thickness: thickness,
          width: width,
          length: length,
          bundles: bundles,
          mbft: mbft,
        });
      }
    });

    if (list.length > 0) {
      tableJoin.join(list, table);
    }

    return [table, list];
  },

  join: (list, table) => {
    var i = 0;
    var j = 0;

    for (i = 0; i < list.length; i++) {
      for (j = 0; j < table[i + 2].length; j++) {
        table[i + 2][j] = tableJoin.checkItem(list[i], table[i + 2][j]);
      }
    }
  },
  checkItem: (item, rowItem) => {
    var keys;

    for (keys in item) {
      if (keys == rowItem.key) {
        rowItem.val = item[keys];
      }
    }
    return rowItem;
  },
};

